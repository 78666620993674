import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import ApiService from '../services/api';
import { Activity } from 'lucide-react';
import * as Slider from '@radix-ui/react-slider';

const FeatureType = ({ idFeature }) => {
  const featureMap = {
    '-1': 'Seed',
    '1': 'API',
    '3': 'V1',
    '4': 'V2',
    '9': 'Atomic'
  };

  return (
    <span className="px-2 py-1 bg-indigo-100 text-indigo-700 rounded-full text-xs font-medium">
      {featureMap[idFeature] || `Feature ${idFeature}`}
    </span>
  );
};

const StatusBadge = ({ status }) => (
  <span className={`px-2 py-1 rounded-full text-xs font-medium inline-flex items-center
    ${status ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
    {status ? 'Success' : 'Failure'}
  </span>
);

const TimeframeButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg font-medium transition-colors ${
      active 
        ? 'bg-indigo-600 text-white' 
        : 'bg-white text-gray-600 hover:bg-gray-50'
    }`}
  >
    {children}
  </button>
);

const ValidationRow = ({ validation }) => {
  const formattedDate = format(new Date(parseInt(validation.date)), 'MMM dd, yyyy HH:mm:ss');
  
  return (
    <div className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow mb-3">
      {/* Main info line */}
      <div className="flex items-center justify-between mb-2 flex-wrap gap-2">
        <div className="flex items-center gap-4 min-w-0 flex-1">
          <span className="text-sm font-medium text-gray-900 truncate">
            {validation.endpoint}
          </span>
          <span className="text-sm text-gray-500">
            {formattedDate}
          </span>
          <FeatureType idFeature={validation.idFeature} />
        </div>
        <StatusBadge status={validation.status} />
      </div>
      
      {/* Message line */}
      {validation.message && (
        <div className="text-sm text-gray-600 mt-1">
          <span dangerouslySetInnerHTML={{ __html: validation.message }} />
        </div>
      )}
    </div>
  );
};

const DateSliderControl = ({ value, maxDays, onChange, onChangeCommitted }) => (
  <Slider.Root
    className="relative flex items-center select-none touch-none w-full h-5"
    value={[value]}
    max={maxDays}
    step={1}
    onValueChange={onChange}
    onValueCommit={onChangeCommitted}
    aria-label="Date"
  >
    <Slider.Track className="bg-gray-200 relative grow rounded-full h-[3px]">
      <Slider.Range className="absolute bg-indigo-600 rounded-full h-full" />
    </Slider.Track>
    <Slider.Thumb
      className="block w-5 h-5 bg-white shadow-lg border-2 border-indigo-600 rounded-full hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2"
    />
  </Slider.Root>
);

const ProducerValidations = () => {
  const { blockchain, producer } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [timeframe, setTimeframe] = useState('7d');
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [producerData, setProducerData] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getMaxDays = () => {
    switch (timeframe) {
      case '30d': return 30;
      case '45d': return 45;
      default: return 7;
    }
  };

  useEffect(() => {
    const fetchValidationHistory = async () => {
      try {
        setLoading(true);
        const maxDays = getMaxDays();
        const today = new Date();
        const targetDate = new Date(today);
        targetDate.setDate(today.getDate() - (maxDays - sliderValue));
        
        const formattedDate = format(targetDate, 'yyyy-MM-dd');
        setSelectedDate(targetDate);

        const data = await ApiService.getProducerValidations(blockchain, producer, {
          from: formattedDate,
          to: formattedDate
        });
        
        setProducerData(data);
        const sortedHistory = data.history.sort((a, b) => parseInt(b.date) - parseInt(a.date));
        setHistory(sortedHistory);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch data when the slider change is committed, not on every change
    if (sliderValue !== null) {
      fetchValidationHistory();
    }
  }, [blockchain, producer, timeframe]);

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
    setSliderValue(0); // Reset slider to most recent date
  };

  const handleSliderChange = ([value]) => {
    setSliderValue(value);
    const maxDays = getMaxDays();
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() - (maxDays - value));
    setSelectedDate(targetDate);
  };

  const handleSliderChangeCommitted = ([value]) => {
    setSliderValue(value);
    // Fetch the updated data when the user has finished moving the slider
    const fetchValidationHistory = async () => {
      try {
        setLoading(true);
        const maxDays = getMaxDays();
        const today = new Date();
        const targetDate = new Date(today);
        targetDate.setDate(today.getDate() - (maxDays - value));
        
        const formattedDate = format(targetDate, 'yyyy-MM-dd');
        setSelectedDate(targetDate);

        const data = await ApiService.getProducerValidations(blockchain, producer, {
          from: formattedDate,
          to: formattedDate
        });
        
        setProducerData(data);
        const sortedHistory = data.history.sort((a, b) => parseInt(b.date) - parseInt(a.date));
        setHistory(sortedHistory);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchValidationHistory();
  };

  const filterByFeature = (featureId) => {
    setSelectedFeature(selectedFeature === featureId ? null : featureId);
  };

  const filteredHistory = selectedFeature !== null 
    ? history.filter(item => item.idFeature.toString() === selectedFeature)
    : history;

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Activity className="animate-spin h-8 w-8 text-indigo-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error loading validation history: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Producer Info Header */}
      <div className="flex items-center justify-between bg-white p-6 rounded-lg shadow-sm flex-wrap gap-4 md:gap-6">
        <div className="flex items-center gap-4">
          <div className="w-16 h-16 overflow-hidden rounded-lg flex items-center justify-center bg-indigo-50">
            {producerData?.logo ? (
              <img 
                src={producerData.logo} 
                alt={producer}
                className="w-full h-full object-contain"
              />
            ) : (
              <span className="text-indigo-500 text-2xl font-semibold">PR</span>
            )}
          </div>
          
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{producer}</h1>
            <p className="text-gray-500">{blockchain}</p>
          </div>
        </div>

        {/* Time Controls */}
        <div className="flex items-center gap-2 md:gap-3 flex-wrap">
          <TimeframeButton 
            active={timeframe === '7d'} 
            onClick={() => handleTimeframeChange('7d')}
          >
            7 days
          </TimeframeButton>
          <TimeframeButton 
            active={timeframe === '30d'}
            onClick={() => handleTimeframeChange('30d')}
          >
            30 days
          </TimeframeButton>
          <TimeframeButton 
            active={timeframe === '45d'}
            onClick={() => handleTimeframeChange('45d')}
          >
            45 days
          </TimeframeButton>
        </div>
      </div>

      {/* Date Slider */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="mb-2 flex justify-between text-sm text-gray-600">
          <span>{format(new Date().setDate(new Date().getDate() - getMaxDays()), 'MMM d, yyyy')}</span>
          <span>{format(new Date(), 'MMM d, yyyy')}</span>
        </div>
        <DateSliderControl
          value={sliderValue}
          maxDays={getMaxDays()}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
        />
        <div className="mt-2 text-center text-sm font-medium text-indigo-600">
          {format(selectedDate, 'MMMM d, yyyy')}
        </div>
      </div>

      {/* Feature Type Filters - Sticky */}
      <div className="sticky top-0 z-10 bg-white shadow-sm p-4 rounded-lg">
        <div className="flex flex-wrap gap-2">
          {['-1', '1', '3', '4', '9'].map((featureId) => (
            <button
              key={featureId}
              onClick={() => filterByFeature(featureId)}
              className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                selectedFeature === featureId
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-50'
              }`}
            >
              {featureId === '-1' ? 'Seed' : featureId === '1' ? 'API' : featureId === '3' ? 'V1' : featureId === '4' ? 'V2' : 'Atomic'}
            </button>
          ))}
          <button
            onClick={() => setSelectedFeature(null)}
            className={`px-4 py-2 rounded-lg font-medium transition-colors ${
              selectedFeature === null
                ? 'bg-indigo-600 text-white'
                : 'bg-white text-gray-600 hover:bg-gray-50'
            }`}
          >
            All
          </button>
        </div>
      </div>

      {/* Validation Rows */}
      <div className="overflow-y-auto max-h-[calc(100vh-320px)]">
        {filteredHistory.map((validation, index) => (
          <ValidationRow key={`${validation.date}-${index}`} validation={validation} />
        ))}

        {filteredHistory.length === 0 && (
          <div className="text-center text-gray-500 py-8">
            No validation history found for the selected date
          </div>
        )}
      </div>
    </div>
  );
};

export default ProducerValidations;
